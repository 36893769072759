import { GeneralLedgerID } from '../../../../generalLedgers/generalLedgersTypes';
import {
  UserFullName,
  UserID,
  UserImageFile
} from '../../../../users/usersTypes';

export interface CreateSmartContractShareFormUser {
  id: UserID;
  nanoId?: string;
  fullName?: UserFullName;
  image?: {
    file: UserImageFile;
  };
  currentTeam?: {
    nanoId: string;
  };
  generalLedgerId?: GeneralLedgerID;
  shareType?: string;
  share?: number;
  createdStatus?: CreateSmartContractShareFormShareCreatedStatus;
}

export interface CreateSmartContractShareFormDataUser {
  userId: UserID;
  nanoId?: string;
  fullName?: UserFullName;
  image?: {
    file: UserImageFile;
  };
  currentTeam: {
    nanoId: string;
  };
  generalLedgerId?: GeneralLedgerID;
  shareType?: string;
  share?: number;
  createdStatus?: CreateSmartContractShareFormShareCreatedStatus;
}

export interface CreateSmartContractShareFormUsers {
  workers: CreateSmartContractShareFormDataUser[];
  clients: CreateSmartContractShareFormDataUser[];
}

export interface CreateSmartContractShareFormData {
  users: CreateSmartContractShareFormUsers;
  iteration: string;
  withoutEventIds: string[];
}

export enum CreateSmartContractShareFormView {
  SELECT_USERS = 'SELECT_USERS',
  CHANGE_SHARE = 'CHANGE_SHARE'
}

export enum CreateSmartContractShareFormShareCreatedStatus {
  SUCCESS = 'success',
  FAILED = 'failed'
}
