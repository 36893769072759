import { TeamNanoID } from '../teams/teamsTypes';

export class CompanyEventTemplateCache {
  static companyCacheKey(companyNanoId: TeamNanoID) {
    return `company-${companyNanoId}-event-templates`;
  }

  static companiesCacheKey() {
    return `companies-event-templates`;
  }
}
