import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import toNumber from 'lodash/toNumber';
import map from 'lodash/map';

import { UserClient, UserID } from '../../../../../../users/usersTypes';
import { GeneralLedgerID } from '../../../../../../generalLedgers/generalLedgersTypes';
import { TaskID } from '../../../../../../tasks/tasksTypes';

import {
  SmartContractShareIteration,
  SmartContractShareShareType,
  SmartContractShareShareTypes,
  SmartContractShareStatus,
  UpdateSmartContractSharesCacheKeys
} from '../../../../../smartContractSharesTypes';
import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormShareCreatedStatus,
  CreateSmartContractShareFormView
} from '../../CreateSmartContractShareForm.types';

import { BATCH_CREATE_SMART_CONTRACT_SHARES_QUERY } from '../../../../../queries/batchCreateSmartContractShare.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useBatchCreateSmartContractShares } from '../../../../../hooks/useBatchCreateSmartContractShares';

interface BatchCreateSmartContractSharesFormOptions {
  iteration?: SmartContractShareIteration;
  taskIds: TaskID[];
  status: SmartContractShareStatus;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  defaultUsers?: {
    id: UserID;
    client?: UserClient;
    generalLedgerId?: GeneralLedgerID;
    shareType?: SmartContractShareShareType;
    share?: number;
    createdStatus?: CreateSmartContractShareFormShareCreatedStatus;
    currentTeam?: {
      nanoId: string;
    };
  }[];
  afterCreate?: () => void;
}

type CheckCreatedUser = { success: UserID[]; failed: UserID[] };

const emptyUserIds: CheckCreatedUser = { success: [], failed: [] };

const createSmartContractShareFormDefaultValues: CreateSmartContractShareFormData =
  {
    users: {
      clients: [],
      workers: []
    },
    iteration: '',
    withoutEventIds: []
  };

function useBatchCreateSmartContractSharesForm({
  iteration,
  taskIds,
  status,
  cacheKeys,
  defaultUsers,
  afterCreate
}: BatchCreateSmartContractSharesFormOptions) {
  const checkCreatedUser = useRef<CheckCreatedUser>(emptyUserIds);

  const isEmptyDefaultUsers = isEmpty(defaultUsers);
  const defaultValues = useMemo(() => {
    const defaultIteration = iteration ? iteration.toString() : '';

    return isEmptyDefaultUsers
      ? {
          ...createSmartContractShareFormDefaultValues,
          iteration: defaultIteration
        }
      : {
          users: {
            clients: map(
              defaultUsers.filter((user) => user.client),
              (user) => ({
                userId: user.id,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                currentTeam: user.currentTeam
              })
            ),
            workers: map(
              defaultUsers.filter((user) => !user.client),
              (user) => ({
                userId: user.id,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                currentTeam: user.currentTeam
              })
            )
          },
          iteration: defaultIteration,
          withoutEventIds: []
        };
  }, [defaultUsers, isEmptyDefaultUsers, iteration]);

  const [formView, setFormView] = useState(
    isEmptyDefaultUsers
      ? CreateSmartContractShareFormView.SELECT_USERS
      : CreateSmartContractShareFormView.CHANGE_SHARE
  );

  const [disabledSubmit, setDisabledSubmit] = useState(isEmptyDefaultUsers);

  const {
    handleSubmitReactHookForm,
    control,
    resetForm,
    watch,
    setValue,
    getValues
  } = useReactHookForm<CreateSmartContractShareFormData>({
    defaultValues,
    isModalForm: true
  });

  const {
    batchCreateSmartContractShares,
    batchCreateSmartContractSharesLoading,
    batchCreateSmartContractSharesErrorMessage,
    batchCreateSmartContractSharesReset
  } = useBatchCreateSmartContractShares({
    query: BATCH_CREATE_SMART_CONTRACT_SHARES_QUERY,
    cacheKeys
  });

  const handleResetBatchCreateSmartContractSharesForm = useCallback<
    () => void
  >(() => {
    checkCreatedUser.current.failed = [];
    checkCreatedUser.current.success = [];
    resetForm(defaultValues);
    setFormView(
      isEmptyDefaultUsers
        ? CreateSmartContractShareFormView.SELECT_USERS
        : CreateSmartContractShareFormView.CHANGE_SHARE
    );
    setDisabledSubmit(isEmptyDefaultUsers);
    batchCreateSmartContractSharesReset();
  }, [
    batchCreateSmartContractSharesReset,
    defaultValues,
    isEmptyDefaultUsers,
    resetForm
  ]);

  const goToChangeShareView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.CHANGE_SHARE);
  }, []);

  const goToSelectUsersView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.SELECT_USERS);
  }, []);

  useEffect(() => {
    const subscription = watch((data, { type }) => {
      if (formView === CreateSmartContractShareFormView.SELECT_USERS) {
        const emptyData =
          isEmpty(data.users.clients) && isEmpty(data.users.workers);

        disabledSubmit !== emptyData && setDisabledSubmit(emptyData);
      }

      if (batchCreateSmartContractSharesErrorMessage && type === 'change') {
        batchCreateSmartContractSharesReset();
      }
    });
    return () => subscription.unsubscribe();
  }, [
    batchCreateSmartContractSharesErrorMessage,
    batchCreateSmartContractSharesReset,
    disabledSubmit,
    formView,
    watch
  ]);

  return {
    control,
    batchCreateSmartContractSharesLoading,
    batchCreateSmartContractSharesErrorMessage,
    batchCreateSmartContractSharesReset,
    handleResetBatchCreateSmartContractSharesForm,
    handleBatchCreateSmartContractShares: handleSubmitReactHookForm({
      onSubmit: async (data: CreateSmartContractShareFormData) => {
        const allUsers = concat(data.users.clients, data.users.workers);

        const usersForCreating = isEmpty(checkCreatedUser.current.success)
          ? allUsers
          : allUsers.filter(
              (user) => !checkCreatedUser.current.success.includes(user.userId)
            );

        const creatingShares = usersForCreating.map((user) =>
          batchCreateSmartContractShares({
            generalLedgerId: user.generalLedgerId,
            iteration: toNumber(data.iteration) || 0,
            finalAt: !data.iteration ? new Date().toISOString() : undefined,
            shareType: user.shareType as SmartContractShareShareTypes,
            share: toNumber(user.share),
            taskIds,
            status,
            withoutEventIds: data.withoutEventIds
          })
        );

        return Promise.all(creatingShares).then(() => {
          afterCreate?.();
        });
      },
      dirtyFieldsOnly: false
    }),
    setValue,
    disabledSubmit,
    formView,
    goToChangeShareView,
    goToSelectUsersView: isEmptyDefaultUsers ? goToSelectUsersView : undefined,
    oneFormView: !isEmptyDefaultUsers,
    getValues
  };
}

export default useBatchCreateSmartContractSharesForm;
